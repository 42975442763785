import React from 'react';
import { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faMusic, faVideo } from '@fortawesome/free-solid-svg-icons'

import parse from 'html-react-parser';

import MediaFile from 'Util/MediaFile';
import { FormattedMessage, injectIntl } from 'react-intl';

import { _LANG_EN_, _LANG_CY_, CONTENT_TYPE_OVERLAY, STATS_TIME_TO_REGISTER_CLIP } from "Config/constants";

import * as Constants from 'Config/constants';

import * as Session from "Util/Session";

import '../MediaOutput.scss';

import store from 'index';

class MediaOutput extends Component {
    state = {
        data: false
    }

    constructor(props) {
        super(props);
        if (props.data) {
            this.state.data = props.data;
            this.state.type = this.getType(props.data);
        }
        this.videoRef = React.createRef();
        this.audioRef = React.createRef();
        
        this.registeredViewClip = false;
    }

    getType = (data) => {
        if (data.media.fields) {
            const media = Object.keys(data.media.fields).filter(key => {
                return data.media.fields[key];
            });

            return MediaFile.getMediaType(media);
        }
        return 'other';
    }

    renderOther = () => {
        return (
            <div className={"Icon"}>
                <FontAwesomeIcon icon={faFile} size={this.props.size || "10x"} />
                <p>Other File</p>
            </div>
        );
    }
    
    renderText = () => {
        const content = store.uiStore.getContent(CONTENT_TYPE_OVERLAY);
        if(!content) return "";
        
        let key = "po_txt_"; 
        key += (window._LANG_ === _LANG_EN_) ? "en_" : "cy_";
        key += (process.env.REACT_APP_SERVER === "EXT") ? "ext" : "int";
        
        return (content[key] && content[key].comment && parse(content[key].comment)) || this.renderDefaultText();
    }
    
    renderDefaultText = () => {
        const linkCorner = this.props.intl.formatMessage({ id: "page.detail.link.unavailable.corner" });
        const linkRegister = this.props.intl.formatMessage({ id: "page.detail.link.unavailable.register" });
		const linkLogin = this.props.intl.formatMessage({ id: "page.detail.link.unavailable.login" });
        
        return (
            <p>
                <FormattedMessage
                    id={(process.env.REACT_APP_SERVER === "EXT" && "page.detail.text.unavailable.ext") || "page.detail.text.unavailable"}
                    values={{
                        a: msg => <a href={linkCorner}>{msg}</a>,
                        b: msg => <a href={linkRegister}>{msg}</a>,
                        c: msg => <a href={linkLogin}>{msg}</a>,
                        d: msg => <><br/><br/></>
                    }}
                />
            </p>
        );
    }

    renderImageUnavailable = (icon) => {
        const img = (this.state.data.mainthumbnail && this.renderImage()) || icon;
        return (
            <div className={"ImageContainer"}>
                {img}
                <div className={"Overlay"}>
                    { this.renderText() }
                </div>
            </div>
        );
    }

    renderImage = () => {
        return (
            <img
                alt={this.state.data.title || ""}
                src={this.state.data.mainthumbnail && this.state.data.mainthumbnail.getUrl() + '&width=750&height=420'}
                className="Image" />
        );
    }

    getAudioSource = (data) => {
        let source = null;
        if (data.mediafiles.elements) {
            data.mediafiles.elements.forEach(file => {
                const tags = file.getTags();
                if (tags && tags.indexOf('preview') !== -1) {
                    source = file;
                }
            });
        }
        return source;
    }

    renderAudio = () => {
        const source = this.getAudioSource(this.state.data);
        let img = "";
        if (this.state.data.mainthumbnail) {
            img = <img alt={this.state.data.title || ""} src={this.state.data.mainthumbnail.getUrl() + '&width=750&height=420'} />;
        } else {
            img = <FontAwesomeIcon icon={faMusic} size={this.props.size || "10x"} />
        }

        return (
            <div className={"Icon"}>
                {img}
                <audio ref={this.audioRef} className={"Audio"} key={this.state.data.id} controls controlsList="nodownload">
                    <source
                        src={(source && source.getUrl()) || null}
                        type={source && MediaFile.getAudioMIME(source.getFilename())} />
                </audio>
            </div>
        );
    }

    getVideoSource = (data) => {
        let source = null;
        if (data.mediafiles.elements) {
            data.mediafiles.elements.forEach(file => {
                const tags = file.getTags();
                if (tags && tags.indexOf('preview') !== -1) {
                    source = file.getUrl();
                }
            });
        }
        return source;
    }

    renderVideo = () => {
        if (this.state.data.metadata.fields.youtubelink) {
            const videoUrl = `${Constants.YOUTUBE_EMBEDD_URL}${this.state.data.metadata.fields.youtubelink}?rel=0`;

            return (
                <div className={"Youtube"}>
                    <iframe title="youtubeTitle" src={videoUrl} frameBorder="0" />
                </div>
            );
        }
        const source = this.getVideoSource(this.state.data);

        return (
            <video
                ref={this.videoRef}
                className={"Video"}
                key={this.state.data.id}
                controls="controls"
                poster={this.state.data.mainthumbnail && this.state.data.mainthumbnail.getUrl() + '&width=750&height=420'}
                type="video/mp4"
                controlsList="nodownload"
                disablePictureInPicture >
                <source src={source}></source>
            </video>
        );
    }

    renderOutput = () => {
        switch (this.state.type) {
            case 'video':
                if (this.state.data.metadata.fields.status === "published_internal" && !this.props.user) {
                    return this.renderImageUnavailable((<FontAwesomeIcon icon={faVideo} size={this.props.size || "10x"} />));
                } else {
                    return this.renderVideo();
                }
            case 'audio':
                if (this.state.data.metadata.fields.status === "published_internal" && !this.props.user) {
                    return this.renderImageUnavailable((<FontAwesomeIcon icon={faMusic} size={this.props.size || "10x"} />));
                } else {
                    return this.renderAudio();
                }
            case 'pictures':
                return this.renderImage();
            default:
                return this.renderOther();
        }
    }

    render() {
        if (!this.state.data) return "";

        const output = this.renderOutput();

        return (<>{output}</>);
    }

    componentDidMount = () => {
        // prevent context menu must be set via ReactRef
        if (this.videoRef.current) {
            this.videoRef.current.ontimeupdate = (event) => {
                Session.setSessionTimeout();
                if(!this.registeredViewClip && event.target.currentTime > STATS_TIME_TO_REGISTER_CLIP) {
                    this.registeredViewClip = true;
                    store.searchStore.registerViewClip(this.state.data.id);
                }
            };
            this.videoRef.current.oncontextmenu = (event) => {
                event.preventDefault();
            }
        }
        if (this.audioRef.current) {
            this.audioRef.current.ontimeupdate = (event) => {
                Session.setSessionTimeout();
                if(!this.registeredViewClip && event.target.currentTime > STATS_TIME_TO_REGISTER_CLIP) {
                    this.registeredViewClip = true;
                    store.searchStore.registerViewClip(this.state.data.id);
                }
            };
            this.audioRef.current.oncontextmenu = (event) => {
                event.preventDefault();
            }
        }
    }
}

export default injectIntl(MediaOutput);
